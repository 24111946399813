import * as React from "react";
import { Admin, Resource } from 'react-admin';

import {
  DataProvider, //responsible for querying API
  AuthProvider //responsible for handling authentication and authorization
} from './providers';
import {
  ClientList, //custom client list component
  ShowClients,
  Dashboard //custom dashboard component
} from './components';

//import { checkScope } from './functions';



const App = () => (
  <Admin dashboard={Dashboard} authProvider={AuthProvider} dataProvider={DataProvider}>
    {
        permissions => [
          <Resource name="clients" list={ClientList} show={permissions['clients']['show'] ? ShowClients : null} /> //Display list of clients
          /* display resources based on access privileges
          checkScope('clients:list')
          ? <Resource name="clients" list={ClientList}/>
          : null
          */
        ]
        
    }
  </Admin>
);

export default App;