import * as React from "react";
import { useState } from "react";
import { List, Show, TextField, SimpleList, TopToolbar, ListButton } from 'react-admin';
import SubscriptionField from './SubscriptionField'; //custom subscription field component
import ExpirationField from './ExpirationField'; //custom expiration field component
import { useMediaQuery } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import CustomizableDatagrid from '../../gitcomponents/ra-customizable-datagrid/CustomizableDatagrid';

import CustomShow from './Show';
import ShowActivate from './ShowActivate';
import ShowExtend from './ShowExtend';
import ClientFilters from './Filters';

export const ClientList = ({permissions, ...props}) => {

    const isSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    return(
        <List bulkActionButtons={false} exporter={false} filters={<ClientFilters />} {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => `${record.firstname} ${record.lastname}`}
                    secondaryText={record => <ExpirationField record={record} source="expiration" />}
                    tertiaryText={record => <SubscriptionField record={record} source="tier"/>}
                    linkType="show"
                />
            ) : (
                <CustomizableDatagrid rowClick="show">
                    <TextField source="firstname" />
                    <TextField source="lastname" />
                    <SubscriptionField source="tier" />
                    <ExpirationField source="expiration" />
                </CustomizableDatagrid>
            )
            }
        </List>
    );
};



export const ShowClients = props => {
    const {permissions} = props;

    const [showView, setShowView] = useState('show');

    const onThisClick = (e, view) => {
        e.preventDefault();
        setShowView(view);
    }

    const ClientShowActions = ({basePath, data, resource}) => {
        //let clientId = 0;

        let subscriptionId = 0;
        if (data !== null && typeof data !== 'undefined'){
            /*
            if (data.hasOwnProperty('id')){
                clientId = data.id;
            }
            */
           if (data.hasOwnProperty('subscription_id')){
               subscriptionId = data.subscription_id;
           }
        }

        return (
            <TopToolbar>
                <ListButton basePath={basePath} record={data} />
                {
                    showView === 'show' && subscriptionId === 0 && (data?.details || false) &&
                    <>
                        {
                            permissions?.['clients']?.['activate'] &&
                            <ListButton  onClick={(e) => onThisClick(e, 'activate')} record={data} icon={<LocalActivityIcon />} label={'Activate'}/>
                        }
                        {
                            permissions?.['clients']?.['extend'] &&
                            <ListButton  onClick={(e) => onThisClick(e, 'extend')} record={data} icon={<ArrowForwardIcon />} label={'Extend'}/>
                        }
                    </>
                }
                {
                    showView !== 'show' &&
                    <ListButton  onClick={(e) => onThisClick(e, 'show')} record={data} icon={<VisibilityIcon />} label={'Back'}/>
                }
            </TopToolbar>
        )
    };

    const returnToShow = () => {
        setShowView('show');
    }

    const renderShow = () => {
        switch(showView){
            case 'activate':
                return <ShowActivate onReturn={returnToShow} {...props} />;
            case 'extend':
                return <ShowExtend onReturn={returnToShow} {...props} />;
            default:
                return <CustomShow />;
        }
    }

    return(
        <Show actions={<ClientShowActions />} {...props}>
            {renderShow()}
        </Show>
    )
};

