import * as React from "react";
import { useMutation, useNotify, Button } from 'react-admin';
import { Form, Field } from 'react-final-form'
import {AsyncSelect} from '../';


const IdField = (props) => {
    const {input, meta, ...rest} = props;

    let error = false;
    let helper = '';
    if (meta.error && meta.touched){
        error = true;
        helper = meta.error;
    }

    const resource = 'subscriptions/open';
    const payload = {
        pagination: {},
        sort: {
            field: 'id',
            order: 'DESC'
        }
    }
    const optionLabel = (option) => option.label;

    return (
        <AsyncSelect 
            label="Subscription"
            optionLabel={optionLabel}
            payload={payload} 
            resource={resource}
            error={error}
            helperText={helper}
            {...input} 
            {...rest} 
        />
    );
}


const validate = values => {
    const errors = {}
    try {
        if (typeof values.subscription !== 'undefined'){
            if (values.subscription.hasOwnProperty('id')){
                if (values.subscription.id <= 0){
                    errors.subscription = 'Please select a valid subscription';
                }
            } else {
                errors.subscription = 'Please select a subscription';
            }
        } else {
            errors.subscription = 'Please select a subscription';
        }   
    }
    catch(err) {
        errors.subscription = 'Please select a subscription';
    }

    return errors;
}


const FormRender = ({activate, loading}) => (
    <Form
    onSubmit={activate}
    validate={validate}
    render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>  
            <h2>Activate Account</h2>
            <div>
                <Field name="subscription" component={IdField} placeholder="Choose a subscription" />
            </div>
            
            <Button type="submit" label="activate" disabled={loading} />    
        </form>
    )}
    />
);

const ActivateForm = ({ record, onReturn}) => {
    const notify = useNotify();
    const [mutate, { loading }] = useMutation();
    const activate = values => mutate( //values contain json from form
        {
            type: 'update',
            resource: 'clients/activate',
            payload: {id: record.id, data: {subscriptionId: 3} }
        },
        {
            onSuccess: ({data}) => {
                //console.log(values)
                //console.log(data)
                onReturn();
                notify('Account activated', 'info');
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning')
        }
        
    );

    return(
        <FormRender activate={activate} loading={loading}/>
    );

}

export default ActivateForm;