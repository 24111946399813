import * as React from "react";
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ExtendForm from './ExtendForm';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));

const ShowExtend = props => {
    const classes = useStyles();
    const { record } = props;

    const name = record.firstname + ' ' + record.lastname;
    const title = ': ' + name;

    return (
        <>
            <Title title={title} />
            <CardContent>
                <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <ExtendForm {...props} />
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </>

    );
}

export default ShowExtend;
