import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));

const Dashboard = () => {
    const classes = useStyles();

    return (
    <Card>
        <Title title="Welcome to the administration" />
        <CardContent>
            <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}><h3>Richard Vrolijk Phase III CSIS483 Capstone</h3></Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <h4>Administrators</h4>
                        <p>user1: all:all privileges</p>
                        <p>user2: list:clients privileges</p>
                        <p>user3: list:clients show:clients activate:clients</p>
                    </Paper>
                </Grid>               
                <Grid item md={6} xs={12}>
                    <Paper className={classes.paper}>
                        <h4>New Implementations</h4>
                        <Divider/>
                        <p>Activate Client Account</p>
                        <Divider/>
                        <p>Extend Client Trial</p>
                    </Paper>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Paper className={classes.paper}>
                        <h4>
                            Explanations
                        </h4>
                        <Divider />
                        <p>
                            Any trials from the Clients list can be clicked on and an Activate or Extend button will be displayed as long as the administrator has the required privileges.
                            Both Activate and Extending a trial are pseudo functions solely for demonstration purposes. This way, the actual client list is not modified.
                            Although nothing gets update, both functions report to the API and a privilege check is run through the API as well.
                        </p>
                        <Divider/>
                        <p>
                            The activation page displays a dynamic list of open subscriptions, which are subscriptions that have not yet been connected to any client.
                            The extend trial page displays a date and time picker to allows easy selection of the new trial's date.
                        </p>
                    </Paper>
                </Grid>
            </Grid>
            </div>
        </CardContent>
    </Card> 
    );
};

export default Dashboard;