import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    gold: {
        color: 'white',
        padding: '5px',
        borderRadius: '5px',
        background: 'radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%)'
    },
    silver: {
        color: 'white',
        padding: '5px',
        borderRadius: '5px',
        background: 'linear-gradient(to bottom, #C0C0C0 0%, #B1B1B1 100%)',
        filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr=\'#C0C0C0\', endColorstr=\'#B1B1B1\', GradientType=0)',
        border: '1px solid #A2A2A2',
        boxShadow: 'inset 0 1px 0 #CFCFCF',     
    },
    bronze: {
        color: 'white',
        padding: '5px',
        borderRadius: '5px',
        background: 'linear-gradient(to bottom, #CD7F32 0%, #BE7023 100%)',
        filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr=\'#CD7F32\', endColorstr=\'#BE7023\', GradientType=0)',
        border: '1px solid #AF6114',
        boxShadow: 'inset 0 1px 0 #DC8E41'     
    },
    trial: {
        color: 'black',
        padding: '5px',
        borderRadius: '5px',
        borderColor: 'black',
        borderWidth: '1px',
        borderStyle: 'solid',
        background: 'linear-gradient(129deg, rgba(221,255,0,1) 0%, rgba(54,255,113,1) 28%, rgba(206,255,192,1) 100%)'     
    }
});


const SubscriptionField = ({ record, source }) => {
    const classes = useStyles();

    let tierClass = classes.trial;
    let tierName = 'Trial';
    switch(record[source]){
        case 1:
            tierClass = classes.bronze;
            tierName = 'Bronze';
            break;
        case 2:
            tierClass = classes.silver;
            tierName = 'Silver';
            break;
        case 3:
            tierClass = classes.gold;
            tierName = 'Gold';
            break;
        default:
            tierClass = classes.trial
            tierName = 'Trial';
    }

    return(
       <span className={tierClass}>{tierName}</span>
    );
};

export default SubscriptionField;