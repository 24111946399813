import * as React from "react";
import {Component} from "react";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Decimal } from 'decimal.js'

class IntegerField extends Component {
    static defaultProps = {
        placeholder: '0',
        prefix: '',
    }

    constructor(props){
        super(props)

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

        this._inputProps = {};


        if (this.props.prefix){
            this._inputProps = {
                startAdornment: <InputAdornment position="start">{this.props.prefix}</InputAdornment>
            }
        }
        
    }

    handleKeyPress(e){
        if(e.which === 48)
            if (!e.target.value.includes('.') && e.target.value.startsWith('0'))
                e.preventDefault();
            
        if(e.which === 46){
            if (e.target.value.length === 0){
                e.preventDefault();
            } else {
                if(e.target.value.indexOf('.') !== -1) {
                    e.preventDefault();
                }                
            }

        }
    
        if (e.which !== 8 && e.which !== 0 && e.which !== 46 && (e.which < 48 || e.which > 57)) {
            e.preventDefault();
        }         
    }

    handleChange({target}){
        var amount = target.value;
        amount = amount.replace(/[^0-9]/g, '');

        target.value = amount;

    }

    handleFocus({target}){
        let amount = target.value;
        try {
            amount = new Decimal(amount);
        } catch{
            amount = new Decimal(0);
        }

        if(amount.equals(0)){
            target.value = '';
        }
    }

    handleBlur({target}){
        let amount = target.value;
        try {
            amount = new Decimal(amount);
        } catch{
            amount = new Decimal(0);
        }      
        
        target.value = amount.toFixed(0)
    }

    render() {
        let onChange = this.handleChange;
        let onBlur = this.handleBlur;
        let onFocus = this.handleFocus;
        let onKeyPress = this.handleKeyPress;
        if (this.props.onChange){
            onChange = e => { this.handleChange(e); this.props.onChange(e)};
            onBlur = e => {this.handleBlur(e); this.props.onChange(e)}
            onFocus = e => {this.handleFocus(e); this.props.onChange(e)}
            onKeyPress = e => {this.handleKeyPress(e); this.props.onChange(e)}
        }

        return(
                <TextField 
                    {...this.props}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onKeyPress={onKeyPress}
                    type="number"
                    InputProps={this._inputProps}
                    />
        )
    }
}

export default IntegerField;