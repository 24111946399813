import * as React from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useQueryWithStore } from 'react-admin';


  
export default function AsyncSelect(props) {
    const [options, setOptions] = React.useState([]);
    const [value, setValue] = React.useState(null);

    const {resource, payload, optionLabel, ...rest} = props;

    const { loaded, data } = useQueryWithStore({
        type: 'getList',
        resource: resource, 
        payload:payload
    });

    const handleChange = (newValue) => {
        setValue(newValue);
    };    

    let onChange = handleChange;

    if (props.onChange){
        onChange = e => { handleChange(e); props.onChange(e)};
    }    

    React.useEffect(() => {
        if (loaded) {
          setOptions(data);
        }
      }, [loaded, data]);    


    return (
        <Autocomplete
            getOptionLabel={optionLabel}
            options={options}
            style={{ width: 300, display:'inline-block' }}
            loading={!loaded}
            id="controlled-demo"
            value={value}
            onChange={(event, newValue) => onChange(newValue)}
            renderInput={(params) => (
                <TextField
                  {...params}
                  {...rest}
                  //label="Choose subscription"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {!loaded ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}            
 
        />    
    );
  }