import * as React from "react";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    expired: {
        color: 'white',
        padding: '5px',
        borderRadius: '5px',
        background: 'red'
    },
    active: {
        color: 'black',
        padding: '5px',
        borderRadius: '5px',
        background: 'lightGreen'
    }
});


const ExpirationField = ({ record, source }) => {
    const classes = useStyles();

    let expiredClass = classes.active;

    switch(record['expired']){
        case true:
            expiredClass = classes.expired;
            break;
        default:
            expiredClass = classes.active;
    }

    return(
       <span className={expiredClass}>{record[source]}</span>
    );
};

export default ExpirationField;