import inMemoryJWT from './JWTProvider'; //responsible for handling JWT access and refresh tokens
import getAPI from '../api';

const AuthProvider = {
    login: ({ username, password }) => {
        const request = new Request(getAPI() + '/tokens/admin/create', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
            cache: 'no-cache'            
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token, tte, scopeArray, identity }) => {
                inMemoryJWT.setToken(token, tte, scopeArray);
                inMemoryJWT.setIdentity(identity)
            })
    },
    logout: () => {
        const request = new Request(getAPI() + '/tokens/admin', {
            method: 'DELETE',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
            cache: 'no-cache'
        });
        inMemoryJWT.ereaseToken();

        return fetch(request).then(() => '/login');
    },

    checkAuth: () => {
        if (!inMemoryJWT.getToken()) {
            if (localStorage.getItem('scope') !== null){
                
                return inMemoryJWT.getRefreshedToken().then(() => {
                    return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
                });
            }
            return Promise.reject();
        } else {
            return inMemoryJWT.waitForTokenRefresh().then(() => {
                return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
            });
        }
    },
    getIdentity: () => {
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            return inMemoryJWT.getToken() ? Promise.resolve(inMemoryJWT.getIdentity()) : Promise.reject();
        });                 
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401) {
            inMemoryJWT.ereaseToken();
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {    
        return inMemoryJWT.waitForTokenRefresh().then(() => {
            return inMemoryJWT.getToken() ? Promise.resolve(JSON.parse(localStorage.getItem('scope'))) : Promise.reject();
        });
    },
};

export default AuthProvider;