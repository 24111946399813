import getAPI from '../api';

const inMemoryJWTManager = () => {
    let inMemoryJWT = null;
    let isRefreshing = null;
    let logoutEventName = 'ra-logout';
    let refreshEndpoint = getAPI() + '/tokens/admin/refresh';
    let refreshTimeOutId;
    let identity = null;

    const setLogoutEventName = name => logoutEventName = name;
    const setRefreshTokenEndpoint = endpoint => refreshEndpoint = endpoint;

    // This countdown feature is used to renew the JWT before it's no longer valid
    // in a way that is transparent to the user.
    const refreshToken = (delay) => {
        refreshTimeOutId = window.setTimeout(
            getRefreshedToken,
            delay * 1000 - 5000
        ); // Validity period of the token in seconds, minus 5 seconds
    };

    const abordRefreshToken = () => {
        if (refreshTimeOutId) {
            window.clearTimeout(refreshTimeOutId);
        }
    };

    const waitForTokenRefresh = () => {
        if (!isRefreshing) {
            return Promise.resolve();
        }
        return isRefreshing.then(() => {
            isRefreshing = null;
            return true;
        });
    }

    // The method make a call to the refresh-token endpoint
    // If there is a valid cookie, the endpoint will set a fresh jwt in memory.
    const getRefreshedToken = () => {
        const request = new Request(refreshEndpoint, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
            cache: 'no-cache'
        });

        isRefreshing = fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    ereaseToken();
                    global.console.log(
                        'Token renewal failure'
                    );
                    return { token: null };
                }
                return response.json();
            })
            .then(({ token, tte, scopeArray, identity }) => {
                if (token) {
                    setToken(token, tte, scopeArray);
                    setIdentity(identity);
                    return true;
                }
                ereaseToken();
                return false;
            });

        return isRefreshing;
    };

    const setIdentity = (identityJson) => {
        identity = identityJson;
    };

    const getIdentity = () => {
        return identity;
    }

    const getToken = () => inMemoryJWT;

    const setScope = (scope) => {
        const encodedScope = JSON.stringify(scope);
        localStorage.setItem('scope', encodedScope);
    }

    const removeScope = () => {
        localStorage.removeItem('scope');
    }

    const setToken = (token, delay, scope) => {
        inMemoryJWT = token;
        refreshToken(delay);
        setScope(scope);
        return true;
    };

    const ereaseToken = () => {
        inMemoryJWT = null;
        abordRefreshToken();
        removeScope();
        window.localStorage.setItem(logoutEventName, Date.now());
        return true;
    }

    // This listener will allow to disconnect a session of ra started in another tab
    window.addEventListener('storage', (event) => {
        if (event.key === logoutEventName) {
            inMemoryJWT = null;
        }
    });

    return {
        setIdentity,
        getIdentity,
        ereaseToken,
        getRefreshedToken,
        getToken,
        setLogoutEventName,
        setRefreshTokenEndpoint,
        setToken,
        waitForTokenRefresh,
    }
};

export default inMemoryJWTManager();