import * as React from "react";
import { Filter, SearchInput } from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));

const QuickFilter = ({ label }) => {
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={label} />;
};

const ClientFilters = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <QuickFilter source="tier" label="Subscribed" defaultValue={{'expanded': true, 'operator': '>', 'value': '0'}} />
        <QuickFilter source="expiration" label="Active" defaultValue={{'expanded': true, 'operator': '>', 'value': new Date().toISOString().slice(0, 19).replace('T', ' ')}} />
    </Filter>
);

export default ClientFilters;