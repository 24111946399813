import * as React from "react";
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import SubscriptionField from './SubscriptionField';
import ExpirationField from './ExpirationField';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));


const CustomShow = props => {
    const classes = useStyles();
    const { record } = props;
    
    const name = record.firstname + ' ' + record.lastname;
    const title = ': ' + name;

    return (
        <>
            <Title title={title} />
            <CardContent>
                <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Paper className={classes.paper}>{name}</Paper>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Paper className={classes.paper}>
                            <p>Email: {record.email}</p>
                            {record.subscription_id > 0 &&
                                <p>Subscription #: {record.subscription_id}</p>
                            }
                            <SubscriptionField source="tier" {...props}/>
                            <p>Expiration: <ExpirationField source="expiration" {...props}/></p>
                        </Paper>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Paper className={classes.paper}>
                            {record.hasOwnProperty('details') && //only load once details have finished loaded
                            <>
                                <p>{record.details.name}</p>
                                <p>{record.details.address}</p>
                                <p>{record.details.city}, {record.details.state}, {record.details.zip}</p>
                                <p>{record.details.phone}</p>
                                <p>{record.details.email}</p>
                            </>
                            }
                        </Paper>
                    </Grid>
                </Grid>
                </div>
            </CardContent>
        </> 

    );
}

export default CustomShow;
