
import simpleRestProvider from 'ra-data-simple-rest';
import inMemoryJWT from './JWTProvider'; //responsible for handling JWT access and refresh tokens
import { fetchUtils } from 'react-admin';
import getAPI from '../api';

const httpClient = (url, options = {}) => {

    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }

    const token = inMemoryJWT.getToken();
    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
        options.credentials = 'include';
        options.cache = "no-cache";
        return fetchUtils.fetchJson(url, options);
    } else {
        return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
            if (gotFreshToken) {
                options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`);
                options.credentials = 'include';
                options.cache = "no-cache";
            };
            return fetchUtils.fetchJson(url, options);
        });
    }

};

const DataProvider = simpleRestProvider(getAPI(), httpClient);

export default DataProvider;
