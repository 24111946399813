import * as React from "react";
import { useMutation, useNotify, Button } from 'react-admin';
import { Form, Field } from 'react-final-form'
import {DatePicker} from '../';

const initialDate = new Date();

const ExtendInput = (props) => {
    const {input, meta} = props;

    let error = false;
    let helper = '';
    if (meta.error && meta.touched){
        error = true;
        helper = meta.error;
    }

    return (
        <DatePicker {...input}  style={{minWidth: '300px'}} 
            disablePast
            variant="inline"
            margin="normal"
            label="New Trial Expiration Date"
            error={error}
            helperText={helper}
        />
    )
}

const validate = values => {
    const errors = {}

    if(values.newTrialExpiration instanceof Date && !isNaN(values.newTrialExpiration)){
        if (values.newTrialExpiration <= new Date()){
            errors.newTrialExpiration = "Must be future date";
        }
    } else {
        errors.newTrialExpiration = "Not a valid date";
    }

    return errors;
}

const FormRender = ({extend, loading}) => (
    <Form 
    onSubmit={extend}
    validate={validate}
    render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>  
            <h2>Extend Trial</h2>
            <div>
                <Field name="newTrialExpiration" initialValue={initialDate} component={ExtendInput} placeholder="0" />
            </div>
            
            <Button type="submit" label="extend" disabled={loading} />    
        </form>
    )}
    />
);

const ExtendForm = ({ record, onReturn}) => {
    const notify = useNotify();
    const [mutate, { loading }] = useMutation();
    const extend = values => mutate( //values contain json from form
        {
            type: 'update',
            resource: 'clients/extend',
            payload: {id: record.id, data: {subscriptionId: 3} }
        },
        {
            onSuccess: ({data}) => {
                //console.log('submit values')
                //console.log(values)
                //console.log(data)
                onReturn();
                notify('Trial extended', 'info');
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning')
        }
        
    );

    return(
        <FormRender extend={extend} loading={loading}/>
    );

}

export default ExtendForm;